import {
  AppBar,
  Box,
  Link,
  Toolbar,
  Typography,
  Menu,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Divider,
  Modal,
  useMediaQuery
} from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import logo from "assets/images/logo.png";
import "./styles.scss";

import cross from "assets/images/Social-Icon/cross-delete.png";
import HelpLogo from "assets/images/help-icon.png";

import { useDispatch, useSelector } from "react-redux";
import {
  setModalOpen,
  setLoginStatus,
  setLoginModalOpen,
  setLogInLoading,
  setalertMessagetype,
  setalertMessage,
  setaccessToken,
  setSessionExpire,
  SetRedirectPage,
  setAuthuser,
  setProfileStatus,
  setAuthProfileData,
  setNotificationCount,
  setNotificationList,
  setNotificationListCount,
  setWalletBalance,
  setRedirectToChat,
  setEmailVerify,
  setAccountCompleted,
} from "redux/redux-slice";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import dropdwnArrow from "assets/images/dropdown-arrow.png";
import flag from "assets/images/usa.png";
import Messages from "../ToasterMessage/Messages";
import { EquipmentAPIs } from "../../service/api-service";
import {
  getFirebaseToken,
  onMessageListener,
  onForegroundMessage,
} from "./../../firebase";
import { setInterval } from "timers";
import { useParams, useLocation, useNavigate } from "react-router-dom";



/**
 * Header component
 */
var page_path = "";
if (window.location.href.includes("myofficetrailer")) {
  page_path = "myofficetrailer";
}
if (window.location.href.includes("listequipment")) {
  page_path = "listequipment";
}
if (
  window.location.href.includes("learnmore") ||
  window.location.href.includes("equipment-tracking")
) {
  page_path = "learnmore";
}
if (window.location.href.includes("equipmentlending")) {
  page_path = "equipmentlending";
}
if (window.location.href.includes("signup")) {
  page_path = "register";
}
if (window.location.href.includes("download")) {
  page_path = "download";
}

if (window.location.pathname == "/") {
  page_path = "home-page";
}

const Path = page_path;

//without login Access pages
var access_page = "";
if (window.location.href.includes("download")) {
  access_page = "download";
}
if (window.location.href.includes("learnmore")) {
  access_page = "learnmore";
}
if (window.location.href.includes("equipmentlending")) {
  access_page = "lending";
}
if (window.location.href.includes("signup")) {
  access_page = "lending";
}
if (window.location.href.includes("detail")) {
  access_page = "detail";
}
if (window.location.href.includes("terms_and_conditions")) {
  access_page = "terms_and_conditions";
}
if (window.location.href.includes("privacy_policy")) {
  access_page = "privacy_policy";
}
if (window.location.href.includes("equipment-tracking")) {
  access_page = "equipment-tracking";
}
if (window.location.href.includes("share-rental-yard")) {
  access_page = "share-rental-yard";
}
if (window.location.href.includes("order-request/rental-summary")) {
  access_page = "rental-summary";
}
// check page path chat-notification include or not in URL, use for redirect to chat-notification
if (window.location.href.includes("chat-notification")) {
  access_page = "chat-notification";
}

if (window.location.pathname == "/") {
  access_page = "signup";
}

const Header: React.FC<{
  setDisableScroll: Dispatch<SetStateAction<Boolean>>;
}> = ({ setDisableScroll }) => {
  const params = useParams();
  // by GD for location
  const location = useLocation();
  // const navigate = useNavigate();
  // console.log('location : ' , location);
  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );
  const Token = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const UserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const WalletBal = useSelector(
    (state: IStoreValue) => state.equipmentReducer.WalletBalance
  );
  const UserProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const ProfileStatus = useSelector(
    (state: IStoreValue) => state.equipmentReducer.ProfileStatus
  );
  const EmailVerify = useSelector(
    (state: IStoreValue) => state.equipmentReducer.EmailVerify
  );
  const AccountCompleted = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AccountCompleted
  );

  const SessionExpire = useSelector(
    (state: IStoreValue) => state.equipmentReducer.SessionExpire
  );

  const [FirsttimePopup, setFirsttimePopup] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [ProfileData, setProfileData] = useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isMenuOpen, openMenu] = useState<boolean>(false);
  const [ProfileCompletePopup, setProfileCompletePopup] = useState(false);
  const dispatch = useDispatch();
  const openModal = useCallback(() => dispatch(setModalOpen(true)), [dispatch]);
  const openLoginModal = useCallback(
    () => dispatch(setLoginModalOpen(true)),
    [dispatch]
  );

  const toggleMenu = useCallback(() => {
    openMenu(!isMenuOpen);
    setDisableScroll(!isMenuOpen);
  }, [isMenuOpen, setDisableScroll]);

  // start Tool
  const [anchorEll, setAnchorEll] = React.useState<null | HTMLElement>(null);
  const openn = Boolean(anchorEll);
  const handleClickk = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEll(event.currentTarget);
  };
  const handleClosee = () => {
    setAnchorEll(null);
  };
  // Stop

  // start More
  const [anchorElll, setAnchorElll] = React.useState<null | HTMLElement>(null);
  const opennn = Boolean(anchorElll);
  const handleClickkk = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElll(event.currentTarget);
  };
  const handleCloseee = () => {
    setAnchorElll(null);
  };
  // Stop

  // create dynamic QR code
  // const createQRcode = (qrvalue: string) => {
  //   setAnchorEll(null)
  //   setAnchorElll(null)
  //   dispatch(setQrCodeValue(qrvalue));
  //   dispatch(setModalOpen(true));
  // };
  const LoginPopup = () => {
    dispatch(setLoginModalOpen(true));
    dispatch(setLogInLoading(false));
  };

  const logout = async () => {
    try {
      const headers = { Authorization: `${Token}`, request_from: "Web" };
      const Result = await EquipmentAPIs.SaveDevice(
        {
          user_id: UserID.toString(),
          device_token: "",
          //from:"Web"
        },
        headers
      );

      if (Result["type"] == "RXSUCCESS") {
        // localStorage.removeItem("token-info");
        // localStorage.removeItem("firebaseToken");
        localStorage.clear()
        dispatch(setLogInLoading(false));
        dispatch(setLoginStatus(false));
        dispatch(setaccessToken(null));
        dispatch(setProfileStatus(false));
        dispatch(setEmailVerify(0));
        setProfileData([]);
        dispatch(setWalletBalance(0));
        dispatch(setalertMessage("Logout Successfully."));
        dispatch(setalertMessagetype("success"));
        dispatch(setAccountCompleted(0));
        window.location.href = "/";
        // navigate("/");
      } else {
        // localStorage.removeItem("token-info");
        // localStorage.removeItem("firebaseToken");
        localStorage.clear()
        dispatch(setLogInLoading(false));
        dispatch(setLoginStatus(false));
        dispatch(setaccessToken(null));
        dispatch(setProfileStatus(false));
        dispatch(setEmailVerify(0));
        setProfileData([]);
        dispatch(setWalletBalance(0));
        dispatch(setalertMessage("Logout Successfully."));
        dispatch(setalertMessagetype("success"));
        window.location.href = "/";
        // navigate("/");
        return false;
      }
    } catch (err) { }
  };

  const [Logoutconfirm, setLogoutconfirm] = React.useState(false);

  const handleClickOpen = () => {
    setLogoutconfirm(true);
  };

  const handleCloseConfirmlogout = () => {
    setLogoutconfirm(false);
  };

  const handleCloseLogout = () => {
    setLogoutconfirm(false);
    logout();
  };

  const getuserprofile = async (user_id, token) => {
    try {
      const headers = { Authorization: `${token}`, request_from: "Web" };
      const profileData = await EquipmentAPIs.getProfile(
        {
          user_id: user_id.toString(),
          //from:"Web"
        },
        headers
      );
      console.log("profile Data: ", profileData);

      if (profileData["type"] == "RXSUCCESS") {
        setProfileData(profileData["data"]);
        dispatch(setSessionExpire(false));
        dispatch(setLoginStatus(true));
        dispatch(setAuthProfileData(profileData["data"]));

        //check notification permission from user
        permissionFun();

        let WalletBalanc = profileData["data"]["balance"];
        dispatch(setWalletBalance(WalletBalanc));

        // check email verify status
        dispatch(setEmailVerify(profileData["data"]["is_user_verify"]));

        var redirect = "";
        if (profileData["data"]["is_user_verify"] == 0) {
          if (
            profileData["data"]["is_profile_completed"] == "1" ||
            profileData["data"]["is_profile_completed"] == 1
          ) {
            redirect = "home";

            dispatch(setProfileStatus(true));
            FetchNotificationcount(user_id, token);
            dispatch(setAccountCompleted(3));
          } else {
            redirect = "signup";
            dispatch(setAccountCompleted(2));
          }
        } else {
          redirect = "signup";
          dispatch(setAccountCompleted(1));
        }

        if (
          redirect == "signup" &&
          !window.location.href.includes("signup") &&
          !window.location.href.includes("terms_and_conditions") &&
          !window.location.href.includes("privacy_policy")
        ) {
          window.location.href = "/signup";
          // navigate("/signup");

          return false;
        }

        // if (
        //   (profileData["data"]["is_profile_completed"] == "0" || profileData["data"]["is_profile_completed"] == 0)
        //   && !window.location.href.includes("signup")
        //   && !window.location.href.includes("terms_and_conditions")
        //   && !window.location.href.includes("privacy_policy")
        // ) {

        //   //setProfileCompletePopup(true);
        //   window.location.href = "/signup";

        //   return false;
        // }

        dispatch(setaccessToken(token));
        dispatch(setAuthuser(user_id));
      } else if (
        profileData["status"] == false &&
        profileData["slug"] == "Session Expired"
      ) {
        dispatch(setSessionExpire(true));
        return false;
      } else {
        dispatch(setalertMessage(profileData["message"]));
        dispatch(setalertMessagetype("error"));
        return false;
      }
    } catch (err) { }
  };

  const LoadNotificationList = async (user_id, token) => {
    try {
      const headers = { Authorization: `${token}`, request_from: "Web" };
      const NotificationData = await EquipmentAPIs.GetNotifications(
        {
          user_id: user_id.toString(),
          page: 0,
          // from:"Web"
        },
        headers
      );
      if (NotificationData["type"] == "RXSUCCESS") {
        dispatch(setNotificationList(NotificationData.data));
        dispatch(setNotificationListCount(NotificationData.total));
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Fetch Notifications count
  const TotalNotifications = useSelector(
    (state: IStoreValue) => state.equipmentReducer.NotificationCount
  );
  const FetchNotificationcount = async (user_id, token) => {
    try {
      const headers = { Authorization: `${token}`, request_from: "Web" };
      const NotificationData = await EquipmentAPIs.GetNotificationsCount(
        {
          user_id: user_id.toString(),
          // from:"Web"
        },
        headers
      );
      if (NotificationData["type"] == "RXSUCCESS") {
        let count = NotificationData.data.total_notification;
        if (TotalNotifications != count) {
          dispatch(setNotificationCount(count));
          //Load notification listing
          LoadNotificationList(user_id, token);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Get Firebase token
  const handleGetFirebaseToken = async () => {
    getFirebaseToken()
      .then(async (firebaseToken) => {
        localStorage.setItem("firebaseToken", firebaseToken);

        SavedeviceID(firebaseToken);
      })
      .catch((err) =>
        console.error("An error occured while retrieving firebase token. ", err)
      );
  };

  //onMessageListener().then(payload => {
  onForegroundMessage()
    .then((payload) => {
      // console.log('comming forground...');

      FetchNotificationcount(UserID, Token);
    })
    .catch((err) => console.log("failed: ", err));

  // Save device token
  const SavedeviceID = async (firebaseToken) => {
    try {
      //let firebaseToken = localStorage.getItem("firebaseToken");

      let auth_user = localStorage.getItem("token-info");
      if (auth_user != "undefined") {
        auth_user = JSON.parse(auth_user);
        if (auth_user != null && auth_user) {
          let uid = auth_user["user_id"];
          let authtoken = auth_user["auth_token"];

          const headers = {
            Authorization: `${authtoken}`,
            request_from: "Web",
          };
          const Result = await EquipmentAPIs.SaveDevice(
            {
              user_id: uid.toString(),
              device_token: firebaseToken.toString(),
              //from:"Web"
            },
            headers
          );
        }
      }
    } catch (err) { }
  };

  // if (isLoggedin && ProfileData['web_device_token'] == null || ProfileData['web_device_token'] == '') {
  //   SavedeviceID();
  // }

  const handleVisibilityChange = useCallback(() => {
    let auth_user = localStorage.getItem("token-info");
    if (auth_user != "undefined") {
      auth_user = JSON.parse(auth_user);
      if (auth_user != null && auth_user) {
        FetchNotificationcount(auth_user["user_id"], auth_user["auth_token"]);
      }
    }
  }, []);

  const HandleRedirection = async (url, newtab = null) => {
    try {
      let profile_status = ProfileStatus;
      let email_status = EmailVerify;
      dispatch(setLoginModalOpen(false));
      if (email_status == 1) {
        dispatch(setalertMessage("Please verify your email first!"));
        dispatch(setalertMessagetype("error"));
        return;
      }

      if (!profile_status) {
        dispatch(setalertMessage("Please update your profile first!"));
        dispatch(setalertMessagetype("error"));
        return;
      }

      const headers = { Authorization: `${Token}`, request_from: "Web" };
      const profileData = await EquipmentAPIs.getProfile(
        {
          user_id: UserID.toString(),
          // from:"Web"
        },
        headers
      );

      if (profileData["type"] == "RXSUCCESS") {
        let location = window.location.href.substring(
          window.location.href.lastIndexOf("/") + 1
        );

        if (location == "notifications" && url == "/notifications") {
          dispatch(setRedirectToChat(true));
        } else {
          window.location.href = url;
          // navigate(url);

        }
      } else if (
        profileData["status"] == false &&
        profileData["slug"] == "Session Expired"
      ) {
        dispatch(setSessionExpire(true));
        return false;
      } else {
        dispatch(setalertMessage(profileData["message"]));
        dispatch(setalertMessagetype("error"));
        return false;
      }
    } catch (err) {
      console.log("error in profile fetch api");
      dispatch(setalertMessagetype("error"));
      dispatch(setalertMessage("something went wrong."));
      return false;
    }
  };

  // Notification permission
  const permissionFun = async () => {
    if (!("Notification" in window)) {
      localStorage.setItem("firebaseToken", null);
      SavedeviceID("null");

      dispatch(
        setalertMessage("This browser does not support desktop notification")
      );
      dispatch(setalertMessagetype("error"));
      //console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Permission granted B");
          handleGetFirebaseToken();
        } else {
          console.log("Rejected");
          localStorage.setItem("firebaseToken", null);
          SavedeviceID("null");
        }
      });
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    if (localStorage.getItem("login-popup")) {
      dispatch(setLoginModalOpen(true));
      localStorage.removeItem("login-popup")
    }
    if (localStorage.getItem("profileCompleted")) {
      setFirsttimePopup(true);
      localStorage.removeItem("profileCompleted");
    }
    if (window.location.pathname !== "/") {
      localStorage.removeItem("equipFilterType")
    }
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // Use for Redirection
  useEffect(() => {
    // check chat-notification redirect parameters for chat window
    if (access_page == "chat-notification") {
      let ChatObj = {
        equipId: params.EquipId,
        senderID: params.FromId,
        reciverID: params.ToId,
        reciverName: params.ToName,
        EquipMake: params.EquipName,
      };
      localStorage.setItem("ChatFromEquipDetail", JSON.stringify(ChatObj));

      if (UserID && UserID != 0) {
        window.location.href = "/notifications";
        // navigate("/notifications");
      } else {
        dispatch(SetRedirectPage(window.location.origin + "/notifications"));
        dispatch(setLoginModalOpen(true));
        dispatch(setLogInLoading(false));
      }
    }
  }, [UserID]);

  useEffect(() => {
    // console.log("use effect running in header");
    openMenu(false);
    let auth_user = localStorage.getItem("token-info");

    if (auth_user != "undefined") {
      auth_user = JSON.parse(auth_user);

      if (auth_user != null && auth_user) {
        //if(auth_user["is_profile_completed"] == "1") {

        dispatch(setaccessToken(auth_user["auth_token"]));
        getuserprofile(auth_user["user_id"], auth_user["auth_token"]);
        dispatch(setLoginStatus(true));
        dispatch(setAuthuser(auth_user["user_id"]));
        LoadNotificationList(auth_user["user_id"], auth_user["auth_token"]);
      } else {
        //for order request summary
        if (window.location.href.includes("order-request/summary")) {
          dispatch(SetRedirectPage(window.location.href));
          localStorage.setItem("RedirectLink", window.location.href);
          LoginPopup();

          dispatch(
            setalertMessage(
              "If you want to see rental detail. Please proceed to login."
            )
          );
          dispatch(setalertMessagetype("error"));

          return;
        }

        // by GD 15 march 2024 for owner redirect to my rental detail from email
        if (window.location.href.includes("myRentalYard")) {
          console.log("redirect Page: ", window.location.href);
          dispatch(SetRedirectPage(window.location.href));
          localStorage.setItem("RedirectLink", window.location.href);
          LoginPopup();
          // navigate("/")
          dispatch(setalertMessage("If you want to proceed. Please proceed to login."));
          dispatch(setalertMessagetype("error"));
          // navigate("/")
          return;
        }
        // GD end

        // by GD 20 march 2024 for owner redirect to my rental detail from email
        if (window.location.href.includes("equipmentRented")) {
          console.log("redirect Page: ", window.location.href);
          dispatch(SetRedirectPage(window.location.href));
          localStorage.setItem("RedirectLink", window.location.href);
          LoginPopup();
          // navigate("/")
          dispatch(setalertMessage("If you want to proceed. Please proceed to login."));
          dispatch(setalertMessagetype("error"));
          // navigate("/")
          return;
        }
        // GD end

        // by GD 13 Feb 2024 changed in if due to how it works pages
        // if (access_page == "equipment-tracking") {
        if (
          access_page == "equipment-tracking"
          || location.pathname.includes('howItWorks')
          || (location.pathname == '/service-directory')
          || (location.pathname == '/equipment-tracking')
          || (location.pathname == '/equipmentlending')
        ) {
        } else if (
          !isLoggedin &&
          access_page != "detail" &&
          access_page != "home" &&
          access_page != "download" &&
          access_page != "learnmore" &&
          access_page != "lending" &&
          access_page != "signup" &&
          access_page != "terms_and_conditions" &&
          access_page != "privacy_policy" &&
          access_page != "equipment-tracking" &&
          access_page != "share-rental-yard" &&
          access_page != "rental-summary" &&
          access_page != "chat-notification" &&
          !officeTrailerPaths.includes(access_page)
        ) {
          window.location.href = "/";
          // navigate("/");
        }
      }
    }

    window.addEventListener("load", updateDimensions);
    window.addEventListener("scroll", updateDimensions);
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("load", updateDimensions);
      window.removeEventListener("scroll", updateDimensions);
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  //Menu bar states
  const [FindEquipment, setFindEquipment] = useState(false);
  const [listEquipMenu, setlistEquipMenu] = useState(false);
  const [appDownload, setappDownload] = useState(false);
  const [learnMore, setlearnMore] = useState(false);
  const [toolBox, settoolBox] = useState(false);
  const [howItWorks, setHowItWorks] = useState(false);
  const updateDimensions = () => {
    setAnchorEl(null);
    setAnchorEll(null);
    setAnchorElll(null);

    let screenwidth = window.innerWidth;
    if (screenwidth > 1400) {
      setFindEquipment(false);
      setlistEquipMenu(false);
      setappDownload(false);
      setlearnMore(false);
      settoolBox(false);
      setHowItWorks(false);
    }

    if (screenwidth < 1400) {
      setFindEquipment(false);
      setlistEquipMenu(false);
      setappDownload(false);
      setlearnMore(true);
      settoolBox(true);
      setHowItWorks(false);
    }
    if (screenwidth < 1200) {
      setFindEquipment(false);
      setlistEquipMenu(false);
      setappDownload(true);
      setlearnMore(true);
      settoolBox(true);
      setHowItWorks(true);
    }
    if (screenwidth < 1024) {
      setFindEquipment(false);
      setlistEquipMenu(true);
      setappDownload(true);
      setlearnMore(true);
      settoolBox(true);
      setHowItWorks(true);
    }
    if (screenwidth < 992) {
      setFindEquipment(true);
      setlistEquipMenu(true);
      setappDownload(true);
      setlearnMore(true);
      settoolBox(true);
      setHowItWorks(true);
    }
  };
  const toolBoxPaths = ["/equipmentlending", "/equipment-tracking", "/service-directory"];
  const officeTrailerPaths = ["/myofficetrailer", "/myRentalYard", "/mySalesYard", "/equipmentRented", "/thumbsUp", "/buildACrew", "/myGps", "/notifications", "/dashboard"];

  const matchesMobileX = useMediaQuery('(max-width:425px)');
  const loginstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: 800,
    //bgcolor: 'rgb(46 46 46)', 
    boxShadow: 24,
    p: 4,
  }
  const OnelinkQaCode = useSelector((state: IStoreValue) => state.equipmentReducer.qrCodeValue);

  return (
    <>
      <Messages />
      <div>
        <Dialog open={Logoutconfirm} aria-labelledby="draggable-dialog-title">
          <Box
            // className="session-expire" 

            sx={{
              background: '#2d2d2d',
              border: '5px solid #faa61a !important',
              borderRadius: '5px',
              padding: '1rem',
            }}>
            <DialogTitle sx={{ color: "#fff", padding: ' 0', margin: '0rem 0rem 1rem 0rem' }} id="draggable-dialog-title">
              Logout
            </DialogTitle>
            <DialogContent sx={{ padding: ' 0', }}>
              <DialogContentText sx={{ color: "#fff", fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '20px', xl: '20px' } }}>
                Are you sure you want to logout?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: ' 0', display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: '1rem' }}>
              <Box
                className="btn-load-more"
                sx={{
                  color: "#faa61a !important",
                  padding: "6px 40px 6px 40px",
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                onClick={handleCloseConfirmlogout}
              >
                No
              </Box>
              <Box
                className="btn-load-more"
                sx={{
                  color: "#faa61a !important",
                  padding: "6px 40px 6px 40px",
                  textAlign: 'center !important',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                onClick={handleCloseLogout}
              >
                Yes
              </Box>

              {/* <Button onClick={handleCloseConfirmlogout}>
                No
              </Button>
              <Button onClick={handleCloseLogout}>Yes</Button> */}
            </DialogActions>
          </Box>
        </Dialog>
      </div>

      {/* Profile complate Popup */}
      <div>
        <Dialog
          open={ProfileCompletePopup}
          aria-labelledby="draggable-dialog-title"
        >
          <Box className="session-expire">
            <DialogTitle style={{ color: "#fff" }} id="draggable-dialog-title">
              Alert
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ color: "#fff", fontSize: "20px" }}>
                Please complete your profile first!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Box
                className="btn-load-more"
                sx={{
                  color: "#faa61a !important",
                  padding: "6px 50px 6px 50px",
                }}
                onClick={() => {
                  //setProfileCompletePopup(true);
                  window.location.href = "/signup";
                  // navigate("/signup");
                }}
              >
                Ok
              </Box>
            </DialogActions>
          </Box>
        </Dialog>
      </div>

      <div>
        <Dialog open={SessionExpire} aria-labelledby="draggable-dialog-title">
          <Box className="session-expire">
            <DialogTitle id="draggable-dialog-title"></DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Box className="active-rentals-heading">
                  <Typography>Session Expired</Typography>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Box className="pagination-section">
                <Box
                  className="btn-load-more"
                  sx={{
                    color: "#faa61a !important",
                    padding: "6px 50px 6px 50px",
                  }}
                  onClick={handleCloseLogout}
                >
                  OK
                </Box>
              </Box>

              {/* <Button sx={{backgroundColor:"red"}} onClick={handleCloseLogout}>OK</Button> */}
            </DialogActions>
          </Box>
        </Dialog>
      </div>

      <Box sx={{ flexGrow: 1 }} className="header">
        <AppBar>
          <Toolbar
            sx={{
              paddingTop: "10px",
              paddingBottom: "10px",
              justifyContent: "space-between",
            }}
          >
            <Link href="/" sx={{ textDecoration: "none" }}>
              <img src={logo} alt="GoEquipMe" className="header-logo" />
            </Link>
            <Box component={"div"} className="nav-item-wrapper nav-box-wrapper">
              {/* New Menu item Start */}

              <Box className="nav-box">
                {AccountCompleted != 0 ? (
                  <Link
                    className={`nav-link desktop-visible myofficetrailer menus
                     ${
                      // window.location.href.includes("myofficetrailer")
                      // (location.pathname.includes("myofficetrailer") || location.pathname.includes("notifications"))
                      (officeTrailerPaths.includes(location.pathname))
                        ? "menu-active"
                        : ""
                      }`}
                    //href="/myofficetrailer"
                    onClick={() => HandleRedirection("/myofficetrailer")}
                  >
                    <Typography>Office Trailer</Typography>
                  </Link>
                ) : (
                  <Link
                    className={`nav-link desktop-visible
                    ${
                      // (location.pathname.includes("myofficetrailer") || location.pathname.includes("notifications"))
                      (officeTrailerPaths.includes(location.pathname))
                        ? "menu-active"
                        : ""
                      }
                    `}
                    onClick={() => (
                      LoginPopup(), dispatch(SetRedirectPage("myofficetrailer"))
                    )}
                  >
                    <Typography>Office Trailer</Typography>
                  </Link>
                )}

                {/* <Link className="nav-link desktop-visible  home-page menus"
                  href="/" >
                  <Typography>Find Equipment  {AccountCompleted}</Typography>
                </Link> */}

                {AccountCompleted != 0 ? (
                  <Link
                    className={`nav-link desktop-visible  home-page menus
                       ${location.pathname == '/' ? 'menu-active' : ''}`}
                    //href="/"
                    onClick={() => HandleRedirection("/")}
                  >
                    <Typography>Find Equipment </Typography>
                  </Link>
                ) : (
                  <Link
                    className={`nav-link desktop-visible  home-page menus
                    ${location.pathname == '/' ? 'menu-active' : ''}`}
                    href="/"
                  >
                    <Typography>Find Equipment </Typography>
                  </Link>
                )}

                {AccountCompleted != 0 ? (
                  <Link
                    className={`nav-link desktop-visible listequipment menus
                    ${location.pathname.includes('listequipment') ? 'menu-active' : ''}
                    `}
                    //href="/listequipment"
                    //target="_blank"
                    onClick={() => HandleRedirection("/listequipment")}
                  >
                    <Typography>List Equipment</Typography>
                  </Link>
                ) : (
                  <Link
                    className={`nav-link desktop-visible
                    ${location.pathname.includes('listequipment') ? 'menu-active' : ''}`}
                    onClick={() => (
                      LoginPopup(), dispatch(SetRedirectPage("listequipment"))
                    )}
                  >
                    <Typography>List Equipment</Typography>
                  </Link>
                )}

                {/* by GD 13 Feb 2024 for howItWorks page */}

                {AccountCompleted != 0 ? (
                  <Link
                    className={`nav-link desktop-visible  home-page menus
                       ${location.pathname.includes('howItWorks') ? 'menu-active' : ''}`}
                    //href="/"
                    onClick={() => HandleRedirection("/howItWorks")}
                  >
                    <Typography>How It Works</Typography>
                  </Link>
                ) : (
                  <Link
                    className={`nav-link desktop-visible  home-page menus
                    ${location.pathname.includes('howItWorks') ? 'menu-active' : ''}`}
                    href="/howItWorks"
                  >
                    <Typography>How It Works</Typography>
                  </Link>
                )}

                {AccountCompleted != 0 ? (
                  <Link
                    className={`nav-link desktop-visible download menus
                    ${location.pathname.includes('appdownload') ? 'menu-active' : ''}
                    `}
                    onClick={() => HandleRedirection("/appdownload")}
                  >
                    <Typography>App Download</Typography>
                  </Link>
                ) : (
                  <Link
                    className={`nav-link desktop-visible download menus
                    ${location.pathname.includes('appdownload') ? 'menu-active' : ''}`}
                    href="/appdownload"
                  >
                    <Typography>App Download</Typography>
                  </Link>
                )}
                {/* commented by GD 15 Feb 2024 */}
                {/* {AccountCompleted != 0 ? (
                  <Link
                    className={`nav-link desktop-visible download menus
                      ${location.pathname.includes('learnmore') ? 'menu-active' : ''}
                    `}
                    onClick={() => HandleRedirection("/learnmore")}
                  >
                    <Typography>Learn More</Typography>
                  </Link>
                ) : (
                  <Link
                    className={`nav-link desktop-visible learnmore menus
                    ${location.pathname.includes('learnmore') ? 'menu-active' : ''}`}
                    href="/learnmore"
                  >
                    <Typography>Learn More</Typography>
                  </Link>
                )} */}

                {/* New ToolBox Start*/}

                <Box className="drpdown-btn-box">
                  <Button className={`nav-link desktop-visible menus new-more-btn  equipmentlending menus 
                    ${toolBoxPaths.includes(location.pathname) ? "menu-active" : ""}
                  `}>
                    <Typography>
                      {/* Toolbox <img src={dropdwnArrow} alt="" /> */}
                      {/* by PP 26 mar 2024 upper line commented an next line added */}
                      More <img src={dropdwnArrow} alt="" />
                    </Typography>
                  </Button>
                  <Box className="submenu-list" sx={{ width: '130px !important' }}>
                    <hr style={{ width: 0, height: 0, border: "none" }} />
                    <hr style={{ width: 0, height: 0, border: "none" }} />

                    {AccountCompleted != 0 ? (
                      <Link
                        className="submenu-link"
                        onClick={() => HandleRedirection("/equipment-tracking")}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        Equipment Tracking
                      </Link>
                    ) : (
                      <Link
                        className="submenu-link"
                        href="/equipment-tracking"
                        //target="_blank"
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        Equipment Tracking
                      </Link>
                    )}

                    {AccountCompleted != 0 ? (
                      <Link
                        className="submenu-link"
                        onClick={() => HandleRedirection("/equipmentlending")}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        Lending
                      </Link>
                    ) : (
                      <Link
                        className="submenu-link"
                        href="/equipmentlending"
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        Lending
                      </Link>
                    )}

                    {/* by GD for service directory link in header */}
                    {AccountCompleted != 0 ? (
                      <Link
                        className="submenu-link"
                        onClick={() => HandleRedirection("/service-directory")}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        Services Directory
                      </Link>
                    ) : (
                      <Link
                        className="submenu-link"
                        href="/service-directory"
                        //target="_blank"
                        // onClick={() => (
                        //   LoginPopup(), dispatch(SetRedirectPage("service-directory"))
                        // )}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        Services Directory
                      </Link>
                    )}


                    <Link
                      className="submenu-link"
                      href="https://dirtradio.com"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      Dirt Radio
                    </Link>


                  </Box>
                </Box>
                {/* New ToolBox End*/}

                {/* New More Start */}
                <Box
                  // id="more-btn-box"
                  id="more-drpdown-btn-box"
                  className={
                    FindEquipment && page_path == "home-page"
                      ? "menu-active home-page more-btn menus"
                      : listEquipMenu && page_path == "listequipment"
                        ? "menu-active listequipment more-btn menus"
                        : learnMore && page_path == "learnmore"
                          ? "menu-active learnmore more-btn menus"
                          : appDownload && page_path == "download"
                            ? "menu-active download more-btn menus"
                            : toolBox && page_path == "equipmentlending"
                              ? "menu-active download more-btn menus"
                              : toolBox && location.pathname.includes('service-directory')
                                ? "menu-active download more-btn menus"
                                : howItWorks && location.pathname.includes('howItWorks')
                                  ? "menu-active download more-btn menus"
                                  : "more-btn menus"
                  }
                >
                  <Button
                    id="more-btn"
                    className="new-more-btn"
                    style={{ textTransform: "capitalize" }}
                  >
                    More <img src={dropdwnArrow} alt="" />
                  </Button>
                  <Box id="basic-menu" className="dropdwn-pop submenu-list">
                    <hr style={{ width: 0, height: 0, border: "none" }} />
                    <hr style={{ width: 0, height: 0, border: "none" }} />

                    {FindEquipment && (
                      <>
                        {AccountCompleted != 0 ? (
                          <Link
                            className="home-page menus submenu-link"
                            onClick={() => HandleRedirection("/")}
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            Find Equipment
                          </Link>
                        ) : (
                          <Link
                            className="home-page menus submenu-link"
                            href="/"
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            Find Equipment
                          </Link>
                        )}
                      </>
                    )}

                    {listEquipMenu && (
                      <>
                        {AccountCompleted != 0 ? (
                          <Link
                            className="listequipment menus submenu-link"
                            //target="_blank"
                            onClick={() => HandleRedirection("/listequipment")}
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            List Equipment
                          </Link>
                        ) : (
                          <Link
                            className="listequipment menus submenu-link"
                            // target="_blank"
                            //href="/listequipment"
                            onClick={() => (
                              LoginPopup(),
                              dispatch(SetRedirectPage("listequipment")),
                              setAnchorEll(null),
                              setAnchorElll(null)
                            )}
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            List Equipment
                          </Link>
                        )}
                      </>
                    )}

                    {/* by GD 13 Feb 2024 for howItWorks pages */}

                    {howItWorks && (
                      <>
                        {AccountCompleted != 0 ? (
                          <Link
                            className="home-page menus submenu-link"
                            onClick={() => HandleRedirection("/howItWorks")}
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            How It Works
                          </Link>
                        ) : (
                          <Link
                            className="home-page menus submenu-link"
                            href="/howItWorks"
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            How It Works
                          </Link>
                        )}
                      </>
                    )}

                    {appDownload && (
                      <>
                        {AccountCompleted != 0 ? (
                          <Link
                            className="submenu-link"
                            onClick={() => HandleRedirection("/appdownload")}
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            App Download
                          </Link>
                        ) : (
                          <Link
                            className="submenu-link"
                            href="/appdownload"
                            style={{
                              textDecoration: "none",
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            App Download
                          </Link>
                        )}
                      </>
                    )}

                    {/* commented by GD 15 Feb 2024 */}
                    {/* {learnMore && (
                      <>
                        {AccountCompleted != 0 ? (
                          <Link
                            className="learnmore menus submenu-link"
                            onClick={() => HandleRedirection("/learnmore")}
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            Learn More
                          </Link>
                        ) : (
                          <Link
                            className="learnmore menus submenu-link"
                            href="/learnmore"
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            Learn More
                          </Link>
                        )}
                      </>
                    )} */}

                    {toolBox && (
                      <>
                        {AccountCompleted != 0 ? (
                          <Link
                            className="equipmentlending menus submenu-link"
                            onClick={() =>
                              HandleRedirection("/equipmentlending")
                            }
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            Lending
                          </Link>
                        ) : (
                          <Link
                            className="equipmentlending menus submenu-link"
                            href="/equipmentlending"
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            Lending
                          </Link>
                        )}

                        <Link
                          className="submenu-link"
                          href="https://dirtradio.com"
                          target="_blank"
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          Dirt Radio
                        </Link>

                        {AccountCompleted != 0 ? (
                          <Link
                            className="submenu-link"
                            onClick={() =>
                              HandleRedirection("/equipment-tracking")
                            }
                            // target="_blank"
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            Equipment Tracking
                          </Link>
                        ) : (
                          <Link
                            className="submenu-link"
                            href="/equipment-tracking"
                            // target="_blank"
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            Equipment Tracking
                          </Link>
                        )}

                        {/* by GD for service directory link in header */}

                        {AccountCompleted != 0 ? (
                          <Link
                            className="submenu-link"
                            onClick={() => HandleRedirection("/service-directory")}
                            // target="_blank"
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            Services Directory
                          </Link>
                        ) : (
                          <Link
                            className="submenu-link"
                            href="/service-directory"
                            // target="_blank"
                            // onClick={() => (
                            //   LoginPopup(), dispatch(SetRedirectPage("service-directory"))
                            // )}
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            Services Directory
                          </Link>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
                {/* New More End */}
              </Box>
              <Box className="nav-box nav-right-sec">
                {AccountCompleted == 0 ? (
                  <>
                    <Link
                      className="nav-link desktop-visible"
                      onClick={() => LoginPopup()}
                    >
                      <Typography>Sign In or Register</Typography>
                    </Link>
                  </>
                ) : AccountCompleted == 1 || AccountCompleted == 2 ? (
                  <>
                    <Link
                      className="nav-link desktop-visible"
                      onClick={() => handleCloseLogout()}
                    >
                      <Typography>Logout</Typography>
                    </Link>
                  </>
                ) : (
                  <Box className="desktop-visible">
                    <Button
                      className="nav-drpdn "
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    //onClick={handleClickOpen}
                    >
                      <span className="top-part">
                        {TotalNotifications > 0 && (
                          <span className="welcome-notify-numbers">
                            {TotalNotifications}
                          </span>
                        )}
                        Welcome,
                        <span title={ProfileData["first_name"]}>
                          {ProfileData["first_name"]
                            ? ProfileData["first_name"].length > 8
                              ? ProfileData["first_name"].substring(0, 8) +
                              "..."
                              : ProfileData["first_name"]
                            : ""}
                          !
                        </span>
                        <span className="drpdn-icon">
                          <img src={dropdwnArrow} alt="" />
                        </span>
                        <span className="flag-icon">
                          <img src={flag} alt="" />
                        </span>
                      </span>
                      <span className="btm-part">
                        <Typography>
                          Wallet Balance:{" "}
                          <span className="wallet-bal">
                            $
                            {Number(WalletBal) > 0
                              ? Number(WalletBal)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                              : Number(0)}
                          </span>
                        </Typography>
                      </span>
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      className="dropdwn-pop welcome-drp"
                    >
                      <Link
                        style={{ textDecoration: "none" }}
                        href="/profileinfo"
                        onClick={() => HandleRedirection("/profileinfo")}
                      >
                        <MenuItem
                          className="submenu-item"
                          onClick={handleClose}
                        >
                          {/* View & Edit Profile */}
                          Profile & Settings
                        </MenuItem>
                      </Link>
                      {/* <Link
                        style={{ textDecoration: "none" }}
                        //href="/accountsettings"
                        onClick={() => HandleRedirection("/accountsettings")}
                      >
                        <MenuItem
                          className="submenu-item"
                          onClick={handleClose}
                        >
                          Account Settings
                        </MenuItem>
                      </Link> */}
                      <Link
                        style={{ textDecoration: "none" }}
                        href="/wallet"
                        onClick={() => HandleRedirection("/wallet")}
                      >
                        <MenuItem
                          className="submenu-item"
                          onClick={handleClose}
                        >
                          Wallet
                        </MenuItem>
                      </Link>
                      <Link
                        style={{ textDecoration: "none" }}
                        onClick={() => HandleRedirection("/notifications")}
                      >
                        <MenuItem
                          className="submenu-item"
                          onClick={handleClose}
                        >
                          Notifications{" "}
                          {TotalNotifications > 0 && (
                            <span className="notifications-number">
                              {TotalNotifications}
                            </span>
                          )}
                        </MenuItem>
                      </Link>

                      {/* by GD for my service directory link in profile section */}

                      <Link
                        //  target="_blank"
                        href="/myServiceDirectory"
                        sx={{ textDecoration: "none" }}
                      >
                        <MenuItem
                          className="submenu-item"
                          onClick={handleClose}
                        >
                          My Services
                        </MenuItem>
                      </Link>

                      <Link href="/contactus" sx={{ textDecoration: "none" }}>
                        <MenuItem
                          className="submenu-item"
                          onClick={handleClose}
                        >
                          Contact Us
                        </MenuItem>
                      </Link>
                      <Link
                        //  target="_blank"
                        href="/terms_and_conditions"
                        sx={{ textDecoration: "none" }}
                      >
                        <MenuItem
                          className="submenu-item"
                          onClick={handleClose}
                        >
                          Legal
                        </MenuItem>
                      </Link>
                      <hr className="gray-line gray-lineee" />
                      <MenuItem
                        className="submenu-item"
                        onClick={handleClickOpen}
                      >
                        Logout
                      </MenuItem>
                    </Menu>
                  </Box>
                )}
              </Box>

              {/* New Menu item End */}

              {/*  Mobile menu start */}
              <div
                className={isMenuOpen ? "hamburger active" : "hamburger"}
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              {/* <Box className="sidemenu-main" sx={{ height:'100vh', overflowY:'auto' }}> */}
              <ul className={isMenuOpen ? "nav-items active" : "nav-items"} >
                <Box sx={{ overflowY: 'auto', w: '100%' }}>
                  {AccountCompleted != 0 ? (
                    <Link
                      className="nav-item myofficetrailer menus"
                      //href="/myofficetrailer"
                      onClick={() => HandleRedirection("/myofficetrailer")}
                    >
                      <Typography>Office Trailer</Typography>
                    </Link>
                  ) : (
                    <Link
                      className="nav-item"
                      onClick={() => (
                        LoginPopup(), dispatch(SetRedirectPage("myofficetrailer"))
                      )}
                    >
                      <Typography>Office Trailer</Typography>
                    </Link>
                  )}

                  {AccountCompleted != 0 ? (
                    <Link
                      onClick={() => HandleRedirection("/")}
                      className="nav-item home-page menus"
                    >
                      <Typography>Find Equipment</Typography>
                    </Link>
                  ) : (
                    <Link href="/" className="nav-item home-page menus">
                      <Typography>Find Equipment</Typography>
                    </Link>
                  )}
                  {/* <Link href="/" className="nav-item home-page menus">
                  <Typography>Find Equipment</Typography>
                </Link> */}

                  {AccountCompleted != 0 ? (
                    <Link
                      //target="_blank"
                      className="nav-item listequipment menus"
                      //href="/listequipment"
                      onClick={() => HandleRedirection("/listequipment")}
                    >
                      <Typography>List Equipment</Typography>
                    </Link>
                  ) : (
                    <Link
                      className="nav-item"
                      onClick={() => (
                        LoginPopup(), dispatch(SetRedirectPage("listequipment"))
                      )}
                    >
                      <Typography>List Equipment</Typography>
                    </Link>
                  )}

                  {/* by GD 13 Feb 2024 added howItWorks in mobile view */}
                  {AccountCompleted != 0 ? (
                    <Link
                      onClick={() => HandleRedirection("/howItWorks")}
                      className="nav-item home-page menus"
                    >
                      <Typography>How It Works</Typography>
                    </Link>
                  ) : (
                    <Link href="/howItWorks" className="nav-item home-page menus">
                      <Typography>How It Works</Typography>
                    </Link>
                  )}

                  {/* by GD 1 Feb 2024 added service-directory in mobile view */}
                  {AccountCompleted != 0 ? (
                    <Link
                      className="nav-item"
                      //href="/myofficetrailer"
                      onClick={() => HandleRedirection("/service-directory")}
                    >
                      <Typography>Services Directory</Typography>
                    </Link>
                  ) : (
                    <Link
                      className="nav-item"
                      href="/service-directory"
                    // onClick={() => (
                    //   LoginPopup(), dispatch(SetRedirectPage("service-directory"))
                    // )}
                    >
                      <Typography>Services Directory</Typography>
                    </Link>
                  )}

                  {/* by GD 1 Feb 2024 added equipment-tracking in mobile view */}
                  {AccountCompleted != 0 ? (
                    <Link
                      className="nav-item"
                      onClick={() => HandleRedirection("/equipment-tracking")}

                    >
                      <Typography>Equipment Tracking</Typography>

                    </Link>
                  ) : (
                    <Link
                      className="nav-item"
                      href="/equipment-tracking"
                    //target="_blank"

                    >
                      <Typography>Equipment Tracking</Typography>
                    </Link>
                  )}

                  {AccountCompleted != 0 ? (
                    <>
                      <Link
                        className="nav-item"
                        onClick={() => HandleRedirection("/appdownload")}
                      >
                        <Typography>App Download</Typography>
                      </Link>
                      {/* commented by GD 15 Feb 2024 */}
                      {/* <Link
                      onClick={() => HandleRedirection("/learnmore")}
                      className="nav-item learnmore menus"
                    >
                      <Typography>Learn More</Typography>
                    </Link> */}
                      <Link
                        onClick={() => HandleRedirection("/equipmentlending")}
                        className="nav-item"
                      >
                        <Typography>Lending</Typography>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link className="nav-item" href="/appdownload">
                        <Typography>App Download</Typography>
                      </Link>
                      {/* commented by GD 15 Feb 2024 */}
                      {/* <Link
                      href="/learnmore"
                      className="nav-item learnmore menus"
                    >
                      <Typography>Learn More</Typography>
                    </Link> */}
                      <Link href="/equipmentlending" className="nav-item">
                        <Typography>Lending</Typography>
                      </Link>
                    </>
                  )}
                  {/* <Link
                  className="nav-item"
                  href="/appdownload"
                >
                  <Typography>App Download</Typography>
                </Link> 
                <Link href="/learnmore" className="nav-item learnmore menus">
                  <Typography>Learn More</Typography>
                </Link>
                <Link href="/equipmentlending" className="nav-item">
                  <Typography>Lending</Typography>
                </Link>*/}
                  <Link
                    href="https://dirtradio.com"
                    target="_blank"
                    className="nav-item"
                  >
                    <Typography>Dirt Radio</Typography>
                  </Link>
                  {/* by GD 5 march 2024 for profile drop down items */}
                  {
                    AccountCompleted != 0 &&
                    <>
                      <Divider sx={{ backgroundColor: '#faa61a !important', height: '2px', width: '100%', mb: 1 }} />
                      <Link
                        // style={{ textDecoration: "none" }}
                        className="nav-item"
                        href="/profileinfo"
                        onClick={() => HandleRedirection("/profileinfo")}
                      >
                        {/* <Typography>View & Edit Profile</Typography> */}
                        <Typography>Profile & Settings</Typography>
                      </Link>

                      {/* <Link
                      className="nav-item"
                      href="/accountsettings"
                      onClick={() => HandleRedirection("/accountsettings")}
                    >
                      <Typography>Account Settings</Typography>
                    </Link> */}

                      <Link
                        className="nav-item"
                        href="/wallet"
                        onClick={() => HandleRedirection("/wallet")}
                      >
                        <Typography>Wallet</Typography>
                      </Link>

                      <Link
                        className="nav-item"
                        onClick={() => HandleRedirection("/notifications")}
                      >
                        <Typography sx={{ width: '100%', display: 'flex !important' }}> Notifications &nbsp;{"  "}
                          {TotalNotifications > 0 && (
                            <span className="notifications-number">
                              {TotalNotifications}
                            </span>
                          )}
                        </Typography>
                      </Link>

                      <Link
                        //  target="_blank"
                        href="/myServiceDirectory"
                        className="nav-item"
                      >
                        <Typography>My Services</Typography>
                      </Link>

                      <Link href="/contactus"
                        className="nav-item"
                      >
                        <Typography>Contact Us</Typography>
                      </Link>

                      <Link
                        //  target="_blank"
                        href="/terms_and_conditions"
                        className="nav-item"
                      >
                        <Typography>Legal</Typography>
                      </Link>
                    </>
                  }
                  {/* end by GD 5 march 2024 for profile drop down */}
                  <Divider sx={{ backgroundColor: '#faa61a !important', height: '2px', width: '100%', mb: 1 }} />
                  {AccountCompleted != 0 ? (
                    <Link className="nav-item" onClick={handleClickOpen}>
                      <Typography>Logout</Typography>
                    </Link>
                  ) : (
                    <>
                      <Link className="nav-item" onClick={() => LoginPopup()}>
                        <Typography>Sign In or Register</Typography>
                      </Link>
                    </>
                  )}
                </Box>
                {/* New Mobile Menu End */}
              </ul>
              {/* </Box> */}
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          className={isMenuOpen ? "overlay active" : "overlay"}
          onClick={toggleMenu}
        />
      </Box>
      {/* profile completion model */}


      <Modal
        keepMounted
        open={FirsttimePopup}
        onClose={() => setFirsttimePopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            width: matchesMobileX ? 'calc(100vw - 100px)' : 800,
            padding: "0px",
          }}>

          <>
            <Box className="login__main">
              <Box className="login-main-inner">
                <Box className="login-fields-div">
                  <Box className="after-msg">
                    <Box className="after-msg-inner">
                      <Typography
                        variant="h4"
                        className="welcome-heading"
                        sx={{
                          textAlign: "center", color: "#fff",
                          fontSize: 'clamp(24px, 3vw, 32px) !important',
                        }}
                      >
                        Welcome, {UserProfileData['first_name']}!
                      </Typography>

                      <Typography className="first-para">
                        Thank you for being part of the Evolution of Equipment Rentals.
                      </Typography>

                      <Typography className="second-para">
                        Use the Office Trailer to manage your rentals and listings.
                        Remember that GoEquipMe is available as an app for your Android or
                        iPhone. <Link className="clickhere-link" target="_blank"
                          href={OnelinkQaCode}
                        >Click here</Link>.
                      </Typography>

                      <Box className="live-help-sec">
                        <img src={HelpLogo} alt="" />
                        <Typography>
                          If you have questions or need help, just click on the LIVE HELP
                          button!
                        </Typography>
                      </Box>

                      <Box
                        className="close"
                        onClick={() => setFirsttimePopup(false)}
                      // onClick={() => (window.location.href = "/")}
                      >
                        <Box className="close-content">
                          <img src={cross} alt="" />
                          <Typography>Close</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                </Box>
              </Box>
            </Box>
          </>
        </Box>
      </Modal>
    </>
  );
};
export default Header;
