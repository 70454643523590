import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';

import { FaPlus, FaMinus } from "react-icons/fa";
import './styles.scss';


const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none"
        },
        ".expandIconWrapper": {
          display: "none"
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block"
        }
      }}
    >


      <div className="expandIconWrapper"><FaMinus /></div>
      <div className="collapsIconWrapper"><FaPlus /></div>
    </Box>
  );
};

const BuildCrewQuestions = () => {
  return (
    <Box className='gq'>
      <Box className='gq-in'>
        <Box>
          <p className='gq-heading'>
            GOT QUESTIONS?
          </p>
          <p className='gq-subheading'>
            Here Are a Few That Are Frequently Asked...
          </p>
        </Box>
        <Box className='gq-content'>
          <p className='gq-content-heading'>
            BUILD A CREW
          </p>
          <Box>
            <Accordion disableGutters>
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>Can I make a lot of money doing this?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes! You can make as much or as little as you want, it’s up to you. Be part of something
                  big, change your life!
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters>
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography>How old do you have to be to participate?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  There is no age limit, well as long as you abide by your state labor laws.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters>
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography>What are the different things I get paid on?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  As an active ‘Crew Boss’ you earn monthly income from your ‘Crew’s’ equipment
                  rentals, equipment sales, GPS sales, equipment loans and print shop items. Get going
                  now and enjoy the rewards of watching your Crew grow.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters>
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                <Typography>Does the income paid to me ever end?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  No, as long as you maintain an active Crew Boss status, payments will continue in
                  perpetuity.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters>
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel5-content"
                id="panel5-header"
              >
                <Typography>Is there a cost to participate?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Yes. A nominal Crew fee is assessed annually for Crew marketing support items.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters>
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel6-content"
                id="panel6-header"
              >
                <Typography>What support items are provided?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    Active ‘Crew Bosses’ will receive a Crew Kit containing information and
                    marketing items. Crew Kit items may include referral cards, crew building tips,
                    swag, etc.
                  </li>
                  <li>
                    Access to monthly and quarterly GoEquipMe video seminars.
                  </li>
                  <li>
                    Additional marketing materials, swag items at Crew discounts.
                  </li>
                  <li>
                    In person Crew seminars at a city near you.
                  </li>
                  <li>
                    Every crew member will have a Crew Boss as an additional resource.
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters>
              <AccordionSummary
                expandIcon={<CustomExpandIcon />}
                aria-controls="panel7-content"
                id="panel7-header"
              >
                <Typography>How do I find out more information?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Simply create a free GoEquipMe user account. Click on Office Trailer and select the
                  Crew tab. <a>Click here</a>.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BuildCrewQuestions;
