import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { FaPlus, FaMinus } from "react-icons/fa";

import './styles.scss'

const CustomExpandIcon = () => {
    return (
        <Box
            sx={{
                ".Mui-expanded & > .collapsIconWrapper": {
                    display: "none"
                },
                ".expandIconWrapper": {
                    display: "none"
                },
                ".Mui-expanded & > .expandIconWrapper": {
                    display: "block"
                }
            }}
        >


            <div className="expandIconWrapper"><FaMinus /></div>
            <div className="collapsIconWrapper"><FaPlus /></div>
        </Box>
    );
};

const ListEqpQuesRent = () => {
    return (
        <Box className='gq'>
            <Box className='gq-in'>
                <Box>
                    <p className='gq-heading'>
                        GOT QUESTIONS?
                    </p>
                    <p className='gq-subheading'>
                        Here Are a Few That Are Frequently Asked...
                    </p>
                </Box>
                <Box className='gq-content'>

                    <Box>
                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography>How much does it cost to list?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    It is FREE to list an item.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography>How old do you have to be to list an item?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    You must be at least 18 years old.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                            >
                                <Typography>Who sets the rental prices?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    The equipment owner sets the rental prices.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel4-content"
                                id="panel4-header"
                            >
                                <Typography>Can I communicate with the renter?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Absolutely, in fact we encourage you to. Good communication with the renter will help to
                                    ensure a good rental experience.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel5-content"
                                id="panel5-header"
                            >
                                <Typography>Can I negotiate rental prices with renters?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Absolutely. But if you would rather not, you are not obligated to.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel6-content"
                                id="panel6-header"
                            >
                                <Typography>Can I deny a requested rental?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Yes, just be sure to provide good communication with your renter to maintain good
                                    reviews.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel7-content"
                                id="panel7-header"
                            >
                                <Typography>Can I block out dates of equipment availability?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Yes, you can block out rental dates for listed items in your Office Trailer.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel8-content"
                                id="panel8-header"
                            >
                                <Typography>How much do I earn with each rental?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    You earn 90% of all rental income.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel9-content"
                                id="panel9-header"
                            >
                                <Typography>How is equipment maintenance handled?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    As the equipment owner you are responsible for informing any routine maintenance
                                    requirements to the renter.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel10-content"
                                id="panel10-header"
                            >
                                <Typography>Does GoEquipMe have any GPS solutions?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Yes. GoEquipMe offers some of the best and most affordable GPS’s on the market.
                                    Click here for more information.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel11-content"
                                id="panel11-header"
                            >
                                <Typography>Who provides equipment transport for rentals?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    As the equipment owner you can select transport preferences for owner and/or renter
                                    transport.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel12-content"
                                id="panel12-header"
                            >
                                <Typography>Who sets transport prices?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Should you elect to provide transport you will provide the cost to transport based on a
                                    minimum transport fee and per mile charge.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel13-content"
                                id="panel13-header"
                            >
                                <Typography>What if I am not comfortable with someone else operating my
                                    equipment?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    With good communication you will quickly be able to get comfortable (or not) with each
                                    rental request. You should deny any rental request where you are not comfortable with
                                    the renter.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel14-content"
                                id="panel14-header"
                            >
                                <Typography>Can I rent my equipment with an operator?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    No. However, you can list your services in GoEquipMe’s Services Directory.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel15-content"
                                id="panel15-header"
                            >
                                <Typography>What are GoEquipMe’s Equipment Damage Protection limits?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Every rental is covered with GoEquipMe’s Equipment Damage Protection
                                    coverage up to $50,000. This amount has been determined to provide adequate
                                    coverage for most smaller items typically rented by individual homeowners.
                                    Larger items are typically rented by contractor’s that maintain umbrella policies
                                    providing greater rental coverage. Please communicate with each renter to better
                                    ascertain equipment damage amounts.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion disableGutters>
                            <AccordionSummary
                                expandIcon={<CustomExpandIcon />}
                                aria-controls="panel16-content"
                                id="panel16-header"
                            >
                                <Typography>What liability protections do I have?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    GoEquipMe’s User Agreement indemnifies equipment owners from any liabilities
                                    caused by the renter.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ListEqpQuesRent
