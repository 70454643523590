import { Box, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { useLocation } from "react-router-dom";
import socialFB from 'assets/images/social_media_FB.png'
import socialIG from 'assets/images/social_media_IG.png'
import downloadAppstore from 'assets/images/download_appstore.png'
import downloadGooglePlay from 'assets/images/download_googleplay.png'
import liveHelp from 'assets/images/gem-livehelp.png'
import './styles.scss'
import ServiceItem from '../service-section/ServiceItem';
import iconLending from 'assets/images/icons_lending-footer.png'
import iconService from 'assets/images/icons_servies-footer.png'
import iconDirt from 'assets/images/icons_dirtradio-footer.png'
import iconLocation from 'assets/images/icons_equipmentlocation-footer.png'
import testimonial1 from 'assets/images/testimonial_01.png'
const testimonialDescription1 = `"As we get more equipment, or at any time that we need equipment, it seems like we can just find it on the app. It's been really beneficial because a lot of the main rental agencies are just booked out for months."
- Casey D., St. George, UT`
const Footer: React.FC = () => {
  const location = useLocation();
  // location == "notifications"
  // console.log("location in footer: ", location.pathname);

  return (
    <>
      <Box className='service-section' >
        <Grid container spacing={0} className='service-section__row' sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto !important' }} >
          <Grid className='service-item-div' item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Link
              href="/equipmentlending"
              sx={{ textDecoration: 'none' }}
              className="service-item-link"
            >
              <ServiceItem
                title='Equipment Financing'
                description='Our Loan Specialists will help YOU get equipment financing for Purchases, Refinances, Cash-outs.'
                icon={iconLending}
              />
            </Link>
          </Grid>
          <Grid className='service-item-div' item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Link
              href="/service-directory"
              sx={{ textDecoration: 'none' }}
              className="service-item-link"
            >
              <ServiceItem
                title='Services Directory'
                description="List your company's services for FREE on the app and reach a wider audience in your area."
                icon={iconService}
              />
            </Link>
          </Grid>
          <Grid className='service-item-div' item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Link
              href="https://dirtradio.com"
              sx={{ textDecoration: 'none' }}
              target="_blank"
              className="service-item-link"
            >
              <ServiceItem
                title='Rock Your Day'
                description='We made a radio station just for you! Ad-free classic rock to pump-up your day!'
                icon={iconDirt}
              />
            </Link>
          </Grid>
          <Grid className='service-item-div' item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Link
              href="/equipment-tracking"
              sx={{ textDecoration: 'none' }}
              target="_blank"
              className="service-item-link"
            >
              <ServiceItem
                // title='Location Services'
                title='Equipment Tracking'
                // description='COMING SOON! Know where your equipment and high-value assets are at all times.'
                // above line commented and next line added by pp on 16 apr 2024
                description='Know where your equipment and high-value assets are at all times.'
                icon={iconLocation}
              />
            </Link>
          </Grid>
        </Grid>
        <Box className='testimonial'>
          <img
            src={testimonial1}
            alt='Testimonial'
            className='testimonial-img'
          />
          <Box className='testimonial-content'>
            <Typography variant='h4' className='testimonial-title'>
              What real people are saying
            </Typography>
            <Typography className='testimonial-description' >
              {testimonialDescription1}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={`footer ${location.pathname == "/service-directory" ? 'footer-sd' : ''}`}>
        <Box className='footer-inner'>
          <Grid className='footer-inner-grid' container spacing={0} sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={4} className="footer-inner-grid-left">
              <Box className='footer-find-us-text'>
                <Typography variant='h5' className='find-us'>Find us on</Typography>
              </Box>
              <Box className='footer-find-social'>
                <Link href='https://www.facebook.com/Goequipme' target="_blank" className='social-icon'>
                  <img
                    src={socialFB}
                    alt='Social Facebook'
                    className='img-social'
                  />
                </Link>
                <Link href='https://www.instagram.com/goequipme/' target="_blank" className='social-icon'>
                  <img
                    src={socialIG}
                    alt='Social Instagram'
                    className='img-social'
                  />
                </Link>
              </Box>

            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={8} sx={{ position: 'relative' }} className="footer-inner-grid-right">
              <Grid container spacing={0} className='footer-download-app'>
                <Grid item xs={4} sm={4} md={6} lg={7} xl={8}>
                  <Box className='footer-download-app footer-get-app'>
                    <Typography variant='h5' className='find-us footer-get-app'>Get the App!</Typography>
                  </Box>
                </Grid>
                <Grid item xs={8} sm={8} md={6} lg={5} xl={4}>
                  <Box className='footer-download-app'>
                    <Link className='download-app-link' target="_blank" href='https://apps.apple.com/us/app/goequipme-peer-to-peer/id1502954874'>
                      <img
                        src={downloadAppstore}
                        alt='Download Appstore'
                        className='img-download-app'
                      />
                    </Link>
                    <Link className='download-app-link' target="_blank" href='https://play.google.com/store/apps/details?id=com.goequipeme'>
                      <img
                        src={downloadGooglePlay}
                        alt='Download GooglePlay'
                        className='img-download-app'
                      />
                    </Link>
                  </Box>
                </Grid>
              </Grid>
              {/* <Box className='footer-download-app'>
            <Typography variant='h5' className='find-us'>Get the App!</Typography>
            <Link className='download-app-link' href='https://apps.apple.com/us/app/goequipme-peer-to-peer/id1502954874'>
              <img
                src={downloadAppstore}
                alt='Download Appstore'
                className='img-download-app'
              />
            </Link>
            <Link className='download-app-link' href='https://play.google.com/store/apps/details?id=com.goequipeme'>
              <img
                src={downloadGooglePlay}
                alt='Download GooglePlay'
                className='img-download-app'
              />
            </Link>
          </Box> */}

            </Grid>
          </Grid>
          {/* <hr className='footer-border-line'/> */}
          {/* <Box className='footer-find-us'>
          <Typography variant='h5' className='find-us'>Find us on</Typography>
          <Link href='https://www.facebook.com/Goequipme'>
            <img
              src={socialFB}
              alt='Social Facebook'
              className='img-social'
            />
          </Link>
          <Link href='https://www.linkedin.com/goequipme'>
            <img
              src={socialIG}
              alt='Social Instagram'
              className='img-social'
            />
          </Link>
        </Box>
        <Box className='footer-middle-border' />
        <Box className='footer-download-app'>
          <Typography variant='h5' className='find-us'>Get the App!</Typography>
          <Link href='https://apps.apple.com/us/app/goequipme-peer-to-peer/id1502954874'>
            <img
              src={downloadAppstore}
              alt='Download Appstore'
              className='img-download-app'
            />
          </Link>
          <Link href='https://play.google.com/store/apps/details?id=com.goequipeme'>
            <img
              src={downloadGooglePlay}
              alt='Download GooglePlay'
              className='img-download-app'
            />
          </Link>
        </Box> */}
          <Box className='footer-links'>
            <Link href="/terms_and_conditions" target="_blank" className='footer-useful-link'>
              <Typography>
                Terms and Conditions
              </Typography>
            </Link>
            <Link href="/privacy_policy" target="_blank" className='footer-useful-link'>
              <Typography>
                Privacy Policy
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Footer;