import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import './styles.scss'

interface IServiceItemProps {
  title: string,
  description: string,
  icon: any
}
const ServiceItem: React.FC<IServiceItemProps> = ({ title, description, icon }) => {
  return (
    <Box className='service-item'>
      <Grid container spacing={0}>
        
        <Grid className='service-item__content-img' item xs={3} sm={3} md={3} lg={3} xl={3} sx={{ textAlign: 'center' }}>
          <img
            src={icon}
            alt={title}
            className='service-item__img'
          />
        </Grid>

        <Grid className='service-item__content' item xs={9} sm={9} md={9} lg={9} xl={9} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography variant='h5' className='service-item__content-title'>{title}</Typography>
          <Typography className='service-item__content-description'>{description}</Typography>
        </Grid>

      </Grid>
    </Box>
  );
};

export default ServiceItem;